import {
  ADD_ARTICLE,
  ADD_VISIT,
  ADD_PATIENT,
  ADD_ADMIN,
  BACKEND_URL,
  AUTH_SUCCESS,
  ADD_PATIENTEMAILS,
  NEWSLETTERS,
} from "../constants/action-types";

const initialState = {
  backendURL: process.env.REACT_APP_DOMAIN,
  admins: [],
  visits: [],
  patients: [],
  patientEmails: [],
  admin: { loggedIn: false },
  newsletters: [],
};

function rootReducer(state = initialState, action) {
  if (action.type === ADD_VISIT) {
    return Object.assign({}, state, {
      visits: action.payload,
    });
  }
  if (action.type === ADD_PATIENT) {
    return Object.assign({}, state, {
      patients: action.payload,
    });
  }
  if (action.type === ADD_PATIENTEMAILS) {
    return Object.assign({}, state, {
      patientEmails: action.payload,
    });
  }
  if (action.type === ADD_ADMIN) {
    return Object.assign({}, state, {
      admins: action.payload,
    });
  }
  if (action.type === BACKEND_URL) {
    return Object.assign({}, state, {
      backendURL: action.payload,
    });
  }
  if (action.type === AUTH_SUCCESS) {
    return Object.assign({}, state, {
      admin: action.payload,
    });
  }
  if (action.type === NEWSLETTERS) {
    return Object.assign({}, state, {
      newsletters: action.payload,
    });
  }
  return state;
}

// export default function(state = {}, action) {
//     switch (action.type) {
//       case ADD_VISIT:
//         return { data: action.payload };
//       default:
//         return state;
//     }
//   }

export default rootReducer;
