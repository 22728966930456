import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grid from "@material-ui/core/Grid";
import { createTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/core/styles";
import { fetchVisits, fetchPatients, fetchAdmins } from "../actions/index";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SignatureCanvas from "react-signature-canvas";
import PainRelief from "../img/painReliefDiagram.jpg";
import InputMask from "react-input-mask";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

let url = process.env.REACT_APP_DOMAIN;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});
const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

class TreatmentNotes extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      // admin view
      admin: false,
      tabStatus: 0,
      // treatment forms
      patient: "",
      visit: "",
      osteopathyTreatment: false,
      massageTreatment: false,
      acupunctureTreatment: false,
      osteoAssessment: false,
      acupunctureAssessment: false,
      // osteo treatment
      rocking: null,
      petrissage: null,
      friction: null,
      vibration: null,
      MyofacialRelease: null,
      tender: null,
      muscleEnergy: null,
      totalBody: null,
      stillTechnique: null,
      Craniosacral: null,
      Visceral: null,
      softTissue: null,
      stretch: null,
      intraOral: null,
      techniqueOther: null,
      back: null,
      neck: null,
      shoulders: null,
      face: null,
      leftArm: null,
      rightArm: null,
      leftLeg: null,
      rightLeg: null,
      gluteus: null,
      abdominals: null,
      chest: null,
      breast: null,
      areasOther: null,
      osteoNotes: null,
      // massage treatment
      Swedish: null,
      frictions: null,
      deepFacial: null,
      triggerPoints: null,
      stretchMassage: null,
      intraOralMassage: null,
      breastMassage: null,
      hydrotherapy: null,
      jointMobilization: null,
      grade: null,
      otherListMassage: null,
      backMassage: null,
      neckMassage: null,
      shouldersMassage: null,
      faceMassage: null,
      leftArmMassage: null,
      rightArmMassage: null,
      leftLegMassage: null,
      rightLegMassage: null,
      hipArea: null,
      abdominalsMassage: null,
      chestMassage: null,
      breastMassageMassage: null,
      areasOtherMassage: null,
      clinFinding: null,
      cliReaction: null,
      selfCare: null,
      // acu treatment
      progInqu: null,
      tongue: null,
      Pulse: null,
      Palpation: null,
      General: null,
      revisedDiag: null,
      // misc
      duration: null,
      // acupuncture assessment
      currentIllness: null,
      medicalHistory: null,
      tongueAssessment: null,
      PulseAssessment: null,
      PalpationAssessment: null,
      tcmDiagnosis: null,
      tcmTreatment: null,
      treatmentPlan: null,
      herbal: null,
      // osteo assessment
      limitations: null,
      treatmentDiscussed: false,
      treatmentGoal: null,
      receivedConsent: null,
      typeTreatment: null,
      frequencyAssessment: null,
      assessmentsPerformed: null,
      anticipatedProgress: null,
      resultsOfAssessment: null,
      remedialExercises: null,
      reassessment: null,
      risksAssessment: null,
      backAssessment: null,
      neckAssessment: null,
      shouldersAssessment: null,
      faceAssessment: null,
      leftArmAssessment: null,
      rightArmAssessment: null,
      leftLegAssessment: null,
      rightLegAssessment: null,
      gluteusAssessment: null,
      abdominalsAssessment: null,
      chestAssessment: null,
      breastAssessment: null,
      areasOtherAssessment: null,
      loading: true,
      canSubmit: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount = () => {
    this.setState({
      sigCanvas: {
        canvasWidth: 800,
        canvasHeight: 600,
      },
    });

    // Sets the values of tags in the head
    document.title = "Treatment Notes";
    let metaTags = document.getElementsByTagName("meta");
    for (let i = 0; i < metaTags.length; i++) {
      if (metaTags[i].name === "description") {
        metaTags[i].content = "";
      }
    }
  };

  componentDidMount() {
    // check if this user can submit.

    this.setState({
      id: this.props.match.params.id,
    });
    // console.log("id", this.props.match.params.id)
    // const context = this.sigCanvas.getCanvas().getContext('2d');
    // get patient info for chosen patient
    axios
      .get(
        process.env.REACT_APP_DOMAIN + `/patients/${this.props.match.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
      .then(({ data }) => {
        // console.log("visits", data)
        this.setState({
          patient: data,
        });
      });

    // get specific visit
    axios
      .get(
        process.env.REACT_APP_DOMAIN +
          `/visits/${this.props.match.params.visit}`, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          })
      .then(({ data }) => {
        console.log("visits", data);
        this.setState({
          visit: data,
          loading: false,
        });

        let notes = data.service[0].treatmentNotes;

        console.log("gotten visit", data.service[0]);
        this.checkIfCanSubmit(notes);
        if (notes) {
          this.setState({
            // treatment forms
            osteopathyTreatment: notes.osteopathyTreatment,
            massageTreatment: notes.massageTreatment,
            acupunctureTreatment: notes.acupunctureTreatment,
            osteoAssessment: notes.osteoAssessment,
            acupunctureAssessment: notes.acupunctureAssessment,
            // osteo treatment
            rocking: notes.rocking,
            petrissage: notes.petrissage,
            friction: notes.friction,
            vibration: notes.vibration,
            MyofacialRelease: notes.MyofacialRelease,
            tender: notes.tender,
            muscleEnergy: notes.muscleEnergy,
            totalBody: notes.totalBody,
            stillTechnique: notes.stillTechnique,
            Craniosacral: notes.Craniosacral,
            Visceral: notes.Visceral,
            softTissue: notes.softTissue,
            stretch: notes.stretch,
            intraOral: notes.intraOral,
            techniqueOther: notes.techniqueOther,
            back: notes.back,
            neck: notes.neck,
            shoulders: notes.shoulders,
            face: notes.face,
            leftArm: notes.leftArm,
            rightArm: notes.rightArm,
            leftLeg: notes.leftLeg,
            rightLeg: notes.rightLeg,
            gluteus: notes.gluteus,
            abdominals: notes.abdominals,
            chest: notes.chest,
            breast: notes.breast,
            areasOther: notes.areasOther,
            osteoNotes: notes.osteoNotes,
            // massage treatment
            Swedish: notes.Swedish,
            frictions: notes.frictions,
            deepFacial: notes.deepFacial,
            triggerPoints: notes.triggerPoints,
            stretchMassage: notes.stretchMassage,
            intraOralMassage: notes.intraOralMassage,
            breastMassage: notes.breastMassage,
            hydrotherapy: notes.hydrotherapy,
            jointMobilization: notes.jointMobilization,
            grade: notes.grade,
            otherListMassage: notes.otherListMassage,
            backMassage: notes.backMassage,
            neckMassage: notes.neckMassage,
            shouldersMassage: notes.shouldersMassage,
            faceMassage: notes.faceMassage,
            leftArmMassage: notes.leftArmMassage,
            rightArmMassage: notes.rightArmMassage,
            leftLegMassage: notes.leftLegMassage,
            rightLegMassage: notes.rightLegMassage,
            hipArea: notes.hipArea,
            abdominalsMassage: notes.abdominalsMassage,
            chestMassage: notes.chestMassage,
            breastMassageMassage: notes.breastMassageMassage,
            areasOtherMassage: notes.areasOtherMassage,
            clinFinding: notes.clinFinding,
            cliReaction: notes.cliReaction,
            selfCare: notes.selfCare,
            // acu treatment
            progInqu: notes.progInqu,
            tongue: notes.tongue,
            Pulse: notes.Pulse,
            Palpation: notes.Palpation,
            General: notes.General,
            revisedDiag: notes.revisedDiag,
            duration: notes.duration,
            // acupuncture assessment
            currentIllness: notes.currentIllness,
            medicalHistory: notes.medicalHistory,
            tongueAssessment: notes.tongueAssessment,
            PulseAssessment: notes.PulseAssessment,
            PalpationAssessment: notes.PalpationAssessment,
            tcmDiagnosis: notes.tcmDiagnosis,
            tcmTreatment: notes.tcmTreatment,
            treatmentPlan: notes.treatmentPlan,
            herbal: notes.herbal,
            // osteo assessment
            limitations: notes.limitations,
            treatmentDiscussed: notes.treatmentDiscussed,
            treatmentGoal: notes.treatmentGoal,
            receivedConsent: notes.receivedConsent,
            typeTreatment: notes.typeTreatment,
            frequencyAssessment: notes.frequencyAssessment,
            assessmentsPerformed: notes.assessmentsPerformed,
            anticipatedProgress: notes.anticipatedProgress,
            resultsOfAssessment: notes.resultsOfAssessment,
            remedialExercises: notes.remedialExercises,
            reassessment: notes.reassessment,
            risksAssessment: notes.risksAssessment,
            backAssessment: notes.backAssessment,
            neckAssessment: notes.neckAssessment,
            shouldersAssessment: notes.shouldersAssessment,
            faceAssessment: notes.faceAssessment,
            leftArmAssessment: notes.leftArmAssessment,
            rightArmAssessment: notes.rightArmAssessment,
            leftLegAssessment: notes.leftLegAssessment,
            rightLegAssessment: notes.rightLegAssessment,
            gluteusAssessment: notes.gluteusAssessment,
            abdominalsAssessment: notes.abdominalsAssessment,
            chestAssessment: notes.chestAssessment,
            breastAssessment: notes.breastAssessment,
            areasOtherAssessment: notes.areasOtherAssessment,
          });
        }
      });

    // const image = new Image();
    // image.src = PainRelief;
    // image.onload = () => {
    //     context.drawImage(image, 0, 0, 652, 446);
    // };
  }

  getPatientByID(patientID) {
    var promise = new Promise((resolve) => {
      var payload = patientID;
      this.getPatientReq(resolve, payload);
    });
    return promise;
  }

  getPatientReq(resolve, payload) {
    // console.log(payload)
    axios
      .get(process.env.REACT_APP_DOMAIN + "/patients/" + payload, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("get request")
        if (response.data.successful === false) {
          // console.log("could not get patient")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
          // console.log(response);
          // console.log('got patient')
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("get request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  clear = () => {
    // console.log(
    //     "clear attempt"
    // )
    this.sigCanvas.clear();
    const context = this.sigCanvas.getCanvas().getContext("2d");

    const image = new Image();
    image.src = PainRelief;
    image.onload = () => {
      context.drawImage(image, 0, 0, 652, 446);
    };
  };

  clearAcu = () => {
    this.consentAcupunctureSig.clear();
  };
  clearOsteo = () => {
    this.consentOsteoSig.clear();
  };
  clearMassage = () => {
    this.consentMassageSig.clear();
  };

  handleCheckBoxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    this.intakeSubmit().then((errorMsg) => {
      // console.log("submit hit");
      // Redirect and save username is no errors with sign up.
    });
  }

  checkIfCanSubmit(notes) {
    console.log("admin", this.props.admin);
    if (this.props.admin) {
      if (
        this.props.admin.permissions === "Administrator" ||
        (this.props.admin.permissions === "User" && !notes)
      ) {
        this.setState({
          canSubmit: true,
        });
        console.log("can submit");
      } else {
        this.setState({
          canSubmit: false,
        });
      }
    }
  }

  intakeSubmit() {
    var promise = new Promise((resolve) => {
      var payload = {
        id: this.props.match.params.visit,
        treatmentNotes: {
          osteopathyTreatment: this.state.osteopathyTreatment,
          massageTreatment: this.state.massageTreatment,
          acupunctureTreatment: this.state.acupunctureTreatment,
          osteoAssessment: this.state.osteoAssessment,
          acupunctureAssessment: this.state.acupunctureAssessment,
          // osteo treatment
          rocking: this.state.rocking,
          petrissage: this.state.petrissage,
          friction: this.state.friction,
          vibration: this.state.vibration,
          MyofacialRelease: this.state.MyofacialRelease,
          tender: this.state.tender,
          muscleEnergy: this.state.muscleEnergy,
          totalBody: this.state.totalBody,
          stillTechnique: this.state.stillTechnique,
          Craniosacral: this.state.Craniosacral,
          Visceral: this.state.Visceral,
          softTissue: this.state.softTissue,
          stretch: this.state.stretch,
          intraOral: this.state.intraOral,
          techniqueOther: this.state.techniqueOther,
          back: this.state.back,
          neck: this.state.neck,
          shoulders: this.state.shoulders,
          face: this.state.face,
          leftArm: this.state.leftArm,
          rightArm: this.state.rightArm,
          leftLeg: this.state.leftLeg,
          rightLeg: this.state.rightLeg,
          gluteus: this.state.gluteus,
          abdominals: this.state.abdominals,
          chest: this.state.chest,
          breast: this.state.breast,
          areasOther: this.state.areasOther,
          osteoNotes: this.state.osteoNotes,
          // massage treatment
          Swedish: this.state.Swedish,
          frictions: this.state.frictions,
          deepFacial: this.state.deepFacial,
          triggerPoints: this.state.triggerPoints,
          stretchMassage: this.state.stretchMassage,
          intraOralMassage: this.state.intraOralMassage,
          breastMassage: this.state.breastMassage,
          hydrotherapy: this.state.hydrotherapy,
          jointMobilization: this.state.jointMobilization,
          grade: this.state.grade,
          otherListMassage: this.state.otherListMassage,
          backMassage: this.state.backMassage,
          neckMassage: this.state.neckMassage,
          shouldersMassage: this.state.shouldersMassage,
          faceMassage: this.state.faceMassage,
          leftArmMassage: this.state.leftArmMassage,
          rightArmMassage: this.state.rightArmMassage,
          leftLegMassage: this.state.leftLegMassage,
          rightLegMassage: this.state.rightLegMassage,
          hipArea: this.state.hipArea,
          abdominalsMassage: this.state.abdominalsMassage,
          chestMassage: this.state.chestMassage,
          breastMassageMassage: this.state.breastMassageMassage,
          areasOtherMassage: this.state.areasOtherMassage,
          clinFinding: this.state.clinFinding,
          cliReaction: this.state.cliReaction,
          selfCare: this.state.selfCare,
          // acu treatment
          progInqu: this.state.progInqu,
          tongue: this.state.tongue,
          Pulse: this.state.Pulse,
          Palpation: this.state.Palpation,
          General: this.state.General,
          revisedDiag: this.state.revisedDiag,
          duration: this.state.duration,
          // acupuncture assessment
          currentIllness: this.state.currentIllness,
          medicalHistory: this.state.medicalHistory,
          tongueAssessment: this.state.tongueAssessment,
          PulseAssessment: this.state.PulseAssessment,
          PalpationAssessment: this.state.PalpationAssessment,
          tcmDiagnosis: this.state.tcmDiagnosis,
          tcmTreatment: this.state.tcmTreatment,
          treatmentPlan: this.state.treatmentPlan,
          herbal: this.state.herbal,
          // osteo assessment
          limitations: this.state.limitations,
          treatmentDiscussed: this.state.treatmentDiscussed,
          treatmentGoal: this.state.treatmentGoal,
          receivedConsent: this.state.receivedConsent,
          typeTreatment: this.state.typeTreatment,
          frequencyAssessment: this.state.frequencyAssessment,
          assessmentsPerformed: this.state.assessmentsPerformed,
          anticipatedProgress: this.state.anticipatedProgress,
          resultsOfAssessment: this.state.resultsOfAssessment,
          remedialExercises: this.state.remedialExercises,
          reassessment: this.state.reassessment,
          risksAssessment: this.state.risksAssessment,
          backAssessment: this.state.backAssessment,
          neckAssessment: this.state.neckAssessment,
          shouldersAssessment: this.state.shouldersAssessment,
          faceAssessment: this.state.faceAssessment,
          leftArmAssessment: this.state.leftArmAssessment,
          rightArmAssessment: this.state.rightArmAssessment,
          leftLegAssessment: this.state.leftLegAssessment,
          rightLegAssessment: this.state.rightLegAssessment,
          gluteusAssessment: this.state.gluteusAssessment,
          abdominalsAssessment: this.state.abdominalsAssessment,
          chestAssessment: this.state.chestAssessment,
          breastAssessment: this.state.breastAssessment,
          areasOtherAssessment: this.state.areasOtherAssessment,
        },
      };

      // console.log("payload", payload)
      this.intakeSubmitReq(resolve, payload);
    });
    return promise;
  }

  intakeSubmitReq(resolve, payload) {
    axios
      .post(url + "/visits/treatmentnotes", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("intake failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          // console.log(response);
          // this.props.fetchVisits()
          this.props.fetchVisits(
            moment().subtract(7, "days").format("YYYY-MM-DD"),
            moment().add(7, "days").format("YYYY-MM-DD")
          );

          // console.log('intake succeeded!')
          this.props.history.push("/patient/" + this.props.match.params.id);
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: 500,
                height: 200,
                marginTop: -100,
                marginLeft: -250,
                textAlign: "center",
              }}
            >
              <Typography component="h1" style={{ fontSize: 25 }}>
                Welcome to Appleby Wellness
              </Typography>
              <Typography component="p" style={{ fontWeight: "lighter" }}>
                Loading in your information.
              </Typography>
              <CircularProgress style={{ margin: "3rem" }} />
            </div>
          </div>
        ) : (
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="row"
              justify="left"
              alignItems="left"
              style={{ marginTop: 0, padding: 70 }}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h4"
                  style={{ textAlign: "left", marginTop: 20 }}
                >
                  Treatment Notes - {this.state.visit.patient.name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: "left", marginTop: 10 }}
                >
                  {String(
                    months[new Date(this.state.visit.date.start).getMonth()]
                  )}{" "}
                  {String(new Date(this.state.visit.date.start).getDate())},{" "}
                  {String(new Date(this.state.visit.date.start).getFullYear())}
                </Typography>
                <br />
                <hr style={{ opacity: "20%" }} />
                <br />
                <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                  Treatment/Assessment Duration (Mins)
                </Typography>
                <br />
                <TextField
                  ref="other"
                  label="Time"
                  id="margin-none"
                  className="other"
                  onChange={this.handleInputChange}
                  style={{ width: "100%" }}
                  variant={"outlined"}
                  name="duration"
                  value={this.state.duration}
                />
                <br /> <br /> <br />
                <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                  Which of the following forms will you be filling out?
                </Typography>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.osteopathyTreatment}
                      onChange={this.handleCheckBoxChange}
                      color="primary"
                      name="osteopathyTreatment"
                      value={this.state.osteopathyTreatment}
                    />
                  }
                  label="Osteopathy Treatment"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.massageTreatment}
                      onChange={this.handleCheckBoxChange}
                      color="primary"
                      name="massageTreatment"
                      value={this.state.massageTreatment}
                    />
                  }
                  label="Massage Treatment"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.acupunctureTreatment}
                      onChange={this.handleCheckBoxChange}
                      color="primary"
                      name="acupunctureTreatment"
                      value={this.state.acupunctureTreatment}
                    />
                  }
                  label="Acupuncture/TCM Treatment"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.osteoAssessment}
                      onChange={this.handleCheckBoxChange}
                      color="primary"
                      name="osteoAssessment"
                      value={this.state.osteoAssessment}
                    />
                  }
                  label="Osteopathy and Massage Assessment"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.acupunctureAssessment}
                      onChange={this.handleCheckBoxChange}
                      color="primary"
                      name="acupunctureAssessment"
                      value={this.state.acupunctureAssessment}
                    />
                  }
                  label="Acupuncture/TCM Assessment"
                />
                <br />
                {this.state.osteopathyTreatment && (
                  <>
                    <hr style={{ opacity: "20%" }} />
                    <Typography
                      variant="h5"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Osteopathy Treatment
                    </Typography>
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Techniques Used
                    </Typography>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rocking}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="rocking"
                          value={this.state.rocking}
                        />
                      }
                      label="Rocking"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.petrissage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="petrissage"
                          value={this.state.petrissage}
                        />
                      }
                      label="Petrissage"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.friction}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="friction"
                          value={this.state.friction}
                        />
                      }
                      label="Friction"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.vibration}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="vibration"
                          value={this.state.vibration}
                        />
                      }
                      label="Vibration"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.MyofacialRelease}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="MyofacialRelease"
                          value={this.state.MyofacialRelease}
                        />
                      }
                      label="Myofacial release"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.tender}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="tender"
                          value={this.state.tender}
                        />
                      }
                      label="Tender Point release"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.muscleEnergy}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="muscleEnergy"
                          value={this.state.muscleEnergy}
                        />
                      }
                      label="Muscle Energy"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.totalBody}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="totalBody"
                          value={this.state.totalBody}
                        />
                      }
                      label="Total Body Adjustment"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.stillTechnique}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="stillTechnique"
                          value={this.state.stillTechnique}
                        />
                      }
                      label="Still Technique"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.Craniosacral}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="Craniosacral"
                          value={this.state.Craniosacral}
                        />
                      }
                      label="Craniosacral"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.Visceral}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="Visceral"
                          value={this.state.Visceral}
                        />
                      }
                      label="Visceral"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.softTissue}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="softTissue"
                          value={this.state.softTissue}
                        />
                      }
                      label="Soft Tissue joint
                                        mobilization
                                        "
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.stretch}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="stretch"
                          value={this.state.stretch}
                        />
                      }
                      label="stretch"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.intraOral}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="intraOral"
                          value={this.state.intraOral}
                        />
                      }
                      label="intra-oral"
                    />
                    <br />

                    <TextField
                      ref="other"
                      label="Other"
                      id="margin-none"
                      className="other"
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      variant={"outlined"}
                      name="techniqueOther"
                      value={this.state.techniqueOther}
                    />
                    <br />
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Areas Treated
                    </Typography>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.back}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="back"
                          value={this.state.back}
                        />
                      }
                      label="back"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.neck}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="neck"
                          value={this.state.neck}
                        />
                      }
                      label="neck"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.shoulders}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="shoulders"
                          value={this.state.shoulders}
                        />
                      }
                      label="shoulders"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.face}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="face"
                          value={this.state.face}
                        />
                      }
                      label="face"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.leftArm}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="leftArm"
                          value={this.state.leftArm}
                        />
                      }
                      label="Left Arm"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rightArm}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="rightArm"
                          value={this.state.rightArm}
                        />
                      }
                      label="Right Arm"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.leftLeg}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="leftLeg"
                          value={this.state.leftLeg}
                        />
                      }
                      label="Left Leg"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rightLeg}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="rightLeg"
                          value={this.state.rightLeg}
                        />
                      }
                      label="Right Leg"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.gluteus}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="gluteus"
                          value={this.state.gluteus}
                        />
                      }
                      label="gluteus"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.abdominals}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="abdominals"
                          value={this.state.abdominals}
                        />
                      }
                      label="abdominals (visceral)"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.chest}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="chest"
                          value={this.state.chest}
                        />
                      }
                      label="chest"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.breast}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="breast"
                          value={this.state.breast}
                        />
                      }
                      label="breast"
                    />
                    <br />

                    <TextField
                      ref="areasOther"
                      label="Other"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      variant={"outlined"}
                      name="areasOther"
                      value={this.state.areasOther}
                    />
                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Notes"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="osteoNotes"
                      value={this.state.osteoNotes}
                    />
                  </>
                )}
                {/* <SignatureCanvas penColor='black' ref={(ref) => { this.sigCanvas = ref }}
                                canvasProps={{ width: 652, height: 446, className: 'sigCanvas' }} /> */}
                <br />
                {this.state.massageTreatment && (
                  <>
                    <hr style={{ opacity: "20%" }} />
                    <Typography
                      variant="h5"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Massage Treatment
                    </Typography>
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Techniques Used
                    </Typography>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.Swedish}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="Swedish"
                          value={this.state.Swedish}
                        />
                      }
                      label="Swedish"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.frictions}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="frictions"
                          value={this.state.frictions}
                        />
                      }
                      label="frictions"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.deepFacial}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="deepFacial"
                          value={this.state.deepFacial}
                        />
                      }
                      label="deep facial"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.triggerPoints}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="triggerPoints"
                          value={this.state.triggerPoints}
                        />
                      }
                      label="trigger points"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.stretchMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="stretchMassage"
                          value={this.state.stretchMassage}
                        />
                      }
                      label="stretch"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.intraOralMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="intraOralMassage"
                          value={this.state.intraOralMassage}
                        />
                      }
                      label="intra-oral"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.breastMassageMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="breastMassageMassage"
                          value={this.state.breastMassageMassage}
                        />
                      }
                      label="breast massage"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.hydrotherapy}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="hydrotherapy"
                          value={this.state.hydrotherapy}
                        />
                      }
                      label="hydrotherapy"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.jointMobilization}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="jointMobilization"
                          value={this.state.jointMobilization}
                        />
                      }
                      label="joint mobilization "
                    />

                    <br />

                    <TextField
                      ref="other"
                      label="Grade"
                      id="margin-none"
                      className="other"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="grade"
                      value={this.state.grade}
                    />
                    <br />
                    <TextField
                      ref="other"
                      label="Other"
                      id="margin-none"
                      className="other"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="otherListMassage"
                      value={this.state.otherListMassage}
                    />
                    <br />
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Areas Treated
                    </Typography>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.backMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="backMassage"
                          value={this.state.backMassage}
                        />
                      }
                      label="back"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.neckMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="neckMassage"
                          value={this.state.neckMassage}
                        />
                      }
                      label="neck"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.shouldersMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="shouldersMassage"
                          value={this.state.shouldersMassage}
                        />
                      }
                      label="shoulders"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.faceMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="faceMassage"
                          value={this.state.faceMassage}
                        />
                      }
                      label="face"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.leftArmMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="leftArmMassage"
                          value={this.state.leftArmMassage}
                        />
                      }
                      label="Left Arm"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rightArmMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="rightArmMassage"
                          value={this.state.rightArmMassage}
                        />
                      }
                      label="Right Arm"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.leftLegMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="leftLegMassage"
                          value={this.state.leftLegMassage}
                        />
                      }
                      label="Left Leg"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rightLegMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="rightLegMassage"
                          value={this.state.rightLegMassage}
                        />
                      }
                      label="Right Leg"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.hipArea}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="hipArea"
                          value={this.state.hipArea}
                        />
                      }
                      label="hip area"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.abdominalsMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="abdominalsMassage"
                          value={this.state.abdominalsMassage}
                        />
                      }
                      label="abdominals"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.chestMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="chestMassage"
                          value={this.state.chestMassage}
                        />
                      }
                      label="chest"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.breastMassage}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="breastMassage"
                          value={this.state.breastMassage}
                        />
                      }
                      label="breast"
                    />
                    <br />
                    <TextField
                      ref="areasOther"
                      label="Other"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="areasOtherMassage"
                      value={this.state.areasOtherMassage}
                    />
                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Clinical Findings"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="clinFinding"
                      value={this.state.clinFinding}
                    />
                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Client Reaction"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="cliReaction"
                      value={this.state.cliReaction}
                    />
                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Recommended Self Care"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="selfCare"
                      value={this.state.selfCare}
                    />
                  </>
                )}
                <br />
                {this.state.acupunctureTreatment && (
                  <>
                    <hr style={{ opacity: "20%" }} />
                    <Typography
                      variant="h5"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Acupuncture Treatment
                    </Typography>
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Progress Inquiry
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Treatment plan progress, patient’s reactions, document
                      Patient’s referral to follow recommendation,
                      contraindication, adjunct modalities/treatment or
                      procedures used.
                    </Typography>

                    <br />

                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Progress Inquiry"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="progInqu"
                      value={this.state.progInqu}
                    />
                    <br />
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Examinations
                    </Typography>
                    <br />
                    <TextField
                      ref="areasOther"
                      label="Tongue"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="tongue"
                      value={this.state.tongue}
                    />
                    <TextField
                      ref="areasOther"
                      label="Pulse"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="Pulse"
                      value={this.state.Pulse}
                    />
                    <TextField
                      ref="areasOther"
                      label="Palpation"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="Palpation"
                      value={this.state.Palpation}
                    />
                    <TextField
                      ref="areasOther"
                      label="General"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="General"
                      value={this.state.General}
                    />

                    <br />
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Revised Diagnosis and Treatment
                    </Typography>

                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      1.Revised TCM Diagnosis/Differentiations; 2.Revised TCM
                      Treatment Plan; 3.Revised Acupuncture Points Prescription;
                      4.Revised Herbal Prescription; 5. Adjunct Modalities
                    </Typography>

                    <br />

                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      multiline
                      rows={3}
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="revisedDiag"
                      value={this.state.revisedDiag}
                    />
                  </>
                )}
                <br />
                {this.state.acupunctureAssessment && (
                  <>
                    <hr style={{ opacity: "20%" }} />
                    <Typography
                      variant="h5"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Acupuncture Assessment
                    </Typography>
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Chief Complaints and Duration
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Current Illness/Symptoms (Energy, Sleep, Appetite, Diet,
                      Thirsty, Body Temperature, Sweating, Body Pains, Bowels,
                      Urine, Menstruations)
                    </Typography>

                    <br />

                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Current Illness"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="currentIllness"
                      value={this.state.currentIllness}
                    />
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Medical History (Previous family health, ongoing problems,
                      past illness/operations, prescription drugs, allergies,
                      nutrition supplement, other health care provider’s
                      referral and /or treatments…)
                    </Typography>

                    <br />

                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Medical History"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="medicalHistory"
                      value={this.state.medicalHistory}
                    />
                    <br />
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Examinations
                    </Typography>
                    <br />
                    <TextField
                      ref="areasOther"
                      label="Tongue"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="tongueAssessment"
                      value={this.state.tongueAssessment}
                    />
                    <TextField
                      ref="areasOther"
                      label="Pulse"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="PulseAssessment"
                      value={this.state.PulseAssessment}
                    />
                    <TextField
                      ref="areasOther"
                      label="Palpation"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="PalpationAssessment"
                      value={this.state.PalpationAssessment}
                    />

                    <br />
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Diagnosis and Treatment
                    </Typography>

                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      TCM Diagnosis and Differentiations:
                    </Typography>

                    <br />
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      multiline
                      rows={3}
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="tcmDiagnosis"
                      value={this.state.tcmDiagnosis}
                    />
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      TCM Treatment Principals:{" "}
                    </Typography>

                    <br />
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      multiline
                      rows={3}
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="tcmTreatment"
                      value={this.state.tcmTreatment}
                    />
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Treatment Plan, Acupuncture Prescription, Length, and Frequency of Treatment/Advice: Acupuncture Herbs Cupping Tuina
                      Moxa Dietary Stress Management Exercise Others:
                      Acupuncture Prescription:
                    </Typography>

                    <br />
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      multiline
                      rows={3}
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="treatmentPlan"
                      value={this.state.treatmentPlan}
                    />
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Herbal Prescription: Formula Name & Dosage. Ingredients of
                      Granule herbs& Dosage& Frequency{" "}
                    </Typography>

                    <br />

                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      multiline
                      rows={3}
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="herbal"
                      value={this.state.herbal}
                    />
                  </>
                )}
                <br />
                <br />
                {this.state.osteoAssessment && (
                  <>
                    <hr style={{ opacity: "20%" }} />
                    <Typography
                      variant="h5"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Osteopathy and Massage Assessment
                    </Typography>
                    <br />

                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Limitations of activities of daily living
                    </Typography>

                    <br />
                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Notes"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="limitations"
                      value={this.state.limitations}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.treatmentDiscussed}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="treatmentDiscussed"
                          value={this.state.treatmentDiscussed}
                        />
                      }
                      label="Treatment plan discussed with Client"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.receivedConsent}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="receivedConsent"
                          value={this.state.receivedConsent}
                        />
                      }
                      label="Received informed consent for treatment plan"
                    />
                    <br />
                    <Typography
                      variant="overline"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Clients Goal / Treatment Goal:
                    </Typography>
                    <br />
                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Notes"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="treatmentGoal"
                      value={this.state.treatmentGoal}
                    />

                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Type / Focus of treatment:
                    </Typography>

                    <br />

                    <TextField
                      id="outlined-multiline-static"
                      onChange={this.handleInputChange}
                      label="Notes"
                      multiline
                      rows={3}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant="outlined"
                      name="typeTreatment"
                      value={this.state.typeTreatment}
                    />
                    <br />
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Frequency and Duration
                    </Typography>
                    <br />
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="frequencyAssessment"
                      multiline
                      rows={3}
                      value={this.state.frequencyAssessment}
                    />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Assessments Performed
                    </Typography>
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="assessmentsPerformed"
                      multiline
                      rows={3}
                      value={this.state.assessmentsPerformed}
                    />
                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Anticipated Progression of Responses:
                    </Typography>
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="anticipatedProgress"
                      multiline
                      rows={3}
                      value={this.state.anticipatedProgress}
                    />

                    <br />

                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Results of Assessments:
                    </Typography>
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="resultsOfAssessment"
                      multiline
                      rows={3}
                      value={this.state.resultsOfAssessment}
                    />

                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Remedial exercises/ Hydrotherapy recommended:
                    </Typography>
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="remedialExercises"
                      multiline
                      rows={3}
                      value={this.state.remedialExercises}
                    />

                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Reassessment schedule:
                    </Typography>
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="reassessment"
                      multiline
                      rows={3}
                      value={this.state.reassessment}
                    />

                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Contraindications / Risks:
                    </Typography>
                    <TextField
                      ref="areasOther"
                      label="Notes"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                      variant={"outlined"}
                      name="risksAssessment"
                      multiline
                      rows={3}
                      value={this.state.risksAssessment}
                    />

                    <br />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginTop: 20 }}
                    >
                      Areas Treated
                    </Typography>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.backAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="backAssessment"
                          value={this.state.backAssessment}
                        />
                      }
                      label="back"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.neckAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="neckAssessment"
                          value={this.state.neckAssessment}
                        />
                      }
                      label="neck"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.shouldersAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="shouldersAssessment"
                          value={this.state.shouldersAssessment}
                        />
                      }
                      label="shoulders"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.faceAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="faceAssessment"
                          value={this.state.faceAssessment}
                        />
                      }
                      label="face"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.leftArmAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="leftArmAssessment"
                          value={this.state.leftArmAssessment}
                        />
                      }
                      label="Left Arm"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rightArmAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="rightArmAssessment"
                          value={this.state.rightArmAssessment}
                        />
                      }
                      label="Right Arm"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.leftLegAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="leftLegAssessment"
                          value={this.state.leftLegAssessment}
                        />
                      }
                      label="Left Leg"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.rightLegAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="rightLegAssessment"
                          value={this.state.rightLegAssessment}
                        />
                      }
                      label="Right Leg"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.gluteusAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="gluteusAssessment"
                          value={this.state.gluteusAssessment}
                        />
                      }
                      label="gluteus"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.abdominalsAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="abdominalsAssessment"
                          value={this.state.abdominalsAssessment}
                        />
                      }
                      label="abdominals (visceral)"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.chestAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="chestAssessment"
                          value={this.state.chestAssessment}
                        />
                      }
                      label="chest"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.breastAssessment}
                          onChange={this.handleCheckBoxChange}
                          color="primary"
                          name="breastAssessment"
                          value={this.state.breastAssessment}
                        />
                      }
                      label="breast"
                    />
                    <br />

                    <TextField
                      ref="areasOther"
                      label="Other"
                      id="margin-none"
                      className="areasOther"
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      variant={"outlined"}
                      name="areasOtherAssessment"
                      value={this.state.areasOtherAssessment}
                    />
                  </>
                )}
                <hr style={{ opacity: "20%" }} />
                <br />
                {!this.state.canSubmit && (
                  <div
                    style={{
                      padding: 20,
                      border: "1px solid #F2994A",
                      backgroundColor: "#FCEBDB",
                      borderRadius: 5,
                    }}
                  >
                    <Typography variant="h6" style={{ textAlign: "left" }}>
                      No Submission Access
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "left", marginTop: 10 }}
                    >
                      As a user, you cannot edit existing treatment notes. If
                      you would like to make changes, please contact the clinic
                      administrators.
                    </Typography>
                  </div>
                )}
                <Button
                  style={{
                    marginTop: 30,
                    width: 120,
                    borderRadius: 20,
                    color: "002BB8",
                  }}
                  type="submit"
                  variant="contained"
                  disabled={!this.state.canSubmit}
                  disableElevation
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  Submit!
                </Button>
              </Grid>
            </Grid>
          </ThemeProvider>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patients: state.patients,
    visits: state.visits,
    admin: state.admin,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
    fetchAdmins,
  })
)(withRouter(TreatmentNotes));
