import React, { createContext, useContext, useState } from "react";
import { Snackbar, IconButton } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

// Create a context to hold the enqueue function.
export const SnackbarContext = createContext();

// Custom hook to allow any component to enqueue a snackbar.
export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

let idCounter = 0;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Provider component that holds the snackbar queue and renders them.
export const SnackbarProvider = ({ children }) => {
  const [snackbars, setSnackbars] = useState([]);

  // Enqueue a snackbar with the specified type ("success", "warning", "error") and message.
  const enqueueSnackbar = (type, message) => {
    const id = idCounter++;
    setSnackbars((prev) => [...prev, { id, type, message }]);
  };

  // Remove a snackbar from the queue when it closes.
  const removeSnackbar = (id) => {
    setSnackbars((prev) => prev.filter((snack) => snack.id !== id));
  };

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar }}>
      {children}
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: 99999,
        }}
      >
        {snackbars.map((snack, index) => (
          <Snackbar
            key={snack.id}
            open={true}
            autoHideDuration={5000}
            onClose={() => removeSnackbar(snack.id)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            style={{ marginBottom: index ? 10 : 0 }}
          >
            <Alert
              severity={snack.type}
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => removeSnackbar(snack.id)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {snack.message}
            </Alert>
          </Snackbar>
        ))}
      </div>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;