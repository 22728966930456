import {
  ADD_ARTICLE,
  ADD_VISIT,
  ADD_PATIENT,
  ADD_ADMIN,
  BACKEND_URL,
  AUTH_SUCCESS,
  ADD_PATIENTEMAILS,
  NEWSLETTERS,
} from "../constants/action-types";
import axios from "axios";

export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload };
}

export function addVisit(payload) {
  return { type: ADD_VISIT, payload };
}

export function addPatient(payload) {
  return { type: ADD_PATIENT, payload };
}

export function addPatientEmails(payload) {
  return { type: ADD_PATIENTEMAILS, payload };
}

export function newsletters(payload) {
  return { type: NEWSLETTERS, payload };
}

export function getBackendURL(payload) {
  return { type: BACKEND_URL, payload };
}

export function successfulAuth(email, adminId, name, permissions) {
  return function (dispatch) {
    console.log("AUTH", email, adminId, name);
    dispatch(setAuth(email, adminId, name, permissions));
  };
}

export function successfulAuthOut() {
  // console.log("log out redux")
  return function (dispatch) {
    dispatch(setAuthOut());
  };
}

export function setAuthOut() {
  // console.log("log out redux auth")
  return {
    type: AUTH_SUCCESS,
    payload: { username: "", adminId: "", name: "", loggedIn: false },
  };
}

export function setAuth(email, adminId, name, permissions) {
  // console.log("log in redux auth")
  return {
    type: AUTH_SUCCESS,
    payload: {
      username: email,
      adminId: adminId,
      name: name,
      loggedIn: true,
      permissions: permissions,
    },
  };
}

export function fetchVisits(startDate, endDate) {
  console.log("fetch visits");
  console.log("start date", startDate);
  console.log("end date", `/visits/get/${startDate}/${endDate}`);
  return function (dispatch) {
    return axios
      .get(process.env.REACT_APP_DOMAIN + `/visits/get/${startDate}/${endDate}`)
      .then(({ data }) => {
        console.log("visits", data.length);
        dispatch(setVisits(data));
      });
  };
}

function setVisits(data) {
  return {
    type: ADD_VISIT,
    payload: data,
  };
}

export function fetchPatients() {
  // console.log("fetch patients")
  return function (dispatch) {
    return axios
      .get(process.env.REACT_APP_DOMAIN + "/patients/get", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(({ data }) => {
        // console.log("patients", data)
        dispatch(setPatients(data));
      });
  };
}

function setPatients(data) {
  return {
    type: ADD_PATIENT,
    payload: data,
  };
}

export function fetchPatientEmails() {
  // console.log("fetch patients")
  return function (dispatch) {
    return axios
      .get(process.env.REACT_APP_DOMAIN + "/patients/getEmails", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(({ data }) => {
        // console.log("patient emails", data);
        dispatch(setPatientEmails(data));
      });
  };
}

function setPatientEmails(data) {
  return {
    type: ADD_PATIENTEMAILS,
    payload: data,
  };
}

export function fetchAdmins() {
  // console.log("fetch admins")
  return async function (dispatch) {
    try {
      const { data } = await axios.get(process.env.REACT_APP_DOMAIN + "/admins/get", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      // console.log("admins fetch", data);
      dispatch(setAdmins(data));
    } catch (error) {
      console.error("Error fetching admins (user may not be logged in):", error);
      // Optionally, dispatch an error action or handle error accordingly
    }
  };
}

function setAdmins(data) {
  return {
    type: ADD_ADMIN,
    payload: data,
  };
}

export function fetchNewsletters() {
  
    // console.log("fetch newsletters")
    return function (dispatch) {
      return axios
        .get(process.env.REACT_APP_DOMAIN + "/newsletters/get", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then(({ data }) => {
          // console.log("newsletters fetch", data);
          dispatch(setNewsletters(data));
        });
    };
  }

function setNewsletters(data) {
  return {
    type: NEWSLETTERS,
    payload: data,
  };
}

