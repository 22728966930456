import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useParams,
} from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";

import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import randomColor from "randomcolor";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";

import { fetchVisits } from "./actions/index";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#7200B8",
    },
    info: {
      main: "#3177EA",
    },
  },
});

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

class PatientSignIn extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      id: null,
      errorSnackbarOpen: false,
      signInDialogue: false,
      signInValidated: false,
      signInSnackbarOpen: false,
      signOutSnackbarOpen: false,
      signOutValidated: false,
      signOutDialogue: false,
      newPatientDialogue: false,
      isReassessment: false,
      focusedVisit: this.props.visits[0],
      currentTime: new Date(),
      colors: randomColor({
        count: 100,
        hue: "blue",
        luminosity: "bright",
      }),
      visits: this.props.visits,
      todayVisits: this.props.visits.filter(function (visit) {
        let date = new Date(visit.date.start);
        let startDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        let endDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1
        );
        return (
          date >= startDate &&
          date <= endDate &&
          !visit.blocked &&
          !visit.rescheduled &&
          !visit.noshow &&
          !visit.signOut.yn
        );
      }),
    };
    this.handlesignInPhoneChange = this.handlesignInPhoneChange.bind(this);
    this.handlesignOutPhoneChange = this.handlesignOutPhoneChange.bind(this);
    // // console.log("new date", new Date())
    // // console.log("start", new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    // // console.log("end", new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))
    // this.props.visits.forEach((visit) => {
    //     // console.log(visit.date.start)
    // })
  }

  componentWillMount = () => {
    this.props.fetchVisits(
      moment().subtract(1, "days").format("YYYY-MM-DD"),
      moment().add(1, "days").format("YYYY-MM-DD")
    );
  };

  componentWillReceiveProps(nextState) {
    // console.log('next state', nextState.visits)

    this.setState({
      visits: nextState.visits,
      todayVisits: nextState.visits.filter(function (visit) {
        let date = new Date(visit.date.start);
        let startDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        let endDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1
        );
        return (
          date >= startDate &&
          date <= endDate &&
          !visit.blocked &&
          !visit.rescheduled &&
          !visit.noshow &&
          !visit.signOut.yn
        );
      }),
    });
  }

  handlesignInPhoneChange(event) {
    // console.log(this.sigCanvas.isEmpty())
    // console.log(event.target.value)
    // console.log(this.state.focusedVisit.patient.cellPhone)
    this.setState({ signInPhone: event.target.value });
  }

  handlesignOutPhoneChange(event) {
    // console.log(event.target.value)
    this.setState({ signOutPhone: event.target.value });
  }

  handleSignInClick = (arg) => {
    console.log("chip click", arg);
    this.setState({
      isReassessment: false,
    });
    if (arg.patient.firstVisit) {
      this.setState({
        newPatientDialogue: true,
      });
    } else if (arg.reassessment && !arg.assessment_completed) {
      this.setState({
        isReassessment: true,
        newPatientDialogue: true,
      });
    } else if (!arg.signIn.yn) {
      this.setState({
        signInDialogue: true,
      });
    } else {
      this.setState({
        signOutDialogue: true,
      });
    }
    this.setState({
      focusedVisit: arg,
      currentTime: new Date(),
    });
    // console.log(this.state.currentTime)
  };

  handleSignInClickClose = () => {
    this.setState({
      signInDialogue: false,
    });
  };

  handleSignInSnackbarClose = () => {
    this.setState({
      signInSnackbarOpen: false,
    });
  };

  handleSignOutSnackbarClose = () => {
    this.setState({
      signOutSnackbarOpen: false,
    });
  };

  handleErrorSnackbarClose = () => {
    this.setState({
      errorSnackbarOpen: false,
    });
  };

  handleSignInClickSubmit = () => {
    // write to visit + error handling
    // console.log("sign in phone", this.state.focusedVisit.patient.cellPhone)
    // console.log("sign in entered phone", this.state.signInPhone)
    // console.log("canvas", this.sigCanvas.isEmpty())
    if (
      this.state.signInPhone === this.state.focusedVisit.patient.cellPhone &&
      !this.sigCanvas.isEmpty()
    ) {
      this.setState({
        signInValidated: true,
        signInDialogue: false,
        signInSnackbarOpen: true,
      });

      // send request to complete signin
      let payload = {
        _id: this.state.focusedVisit._id,
        signIn: {
          yn: true,
          time: this.state.currentTime,
          signature: this.sigCanvas.getCanvas().toDataURL("image/png", 1.0),
        },
      };
      this.signInEvent(payload);
    } else {
      // console.log("something went wrong")
      this.setState({
        errorSnackbarOpen: true,
      });
    }
  };

  signInEvent(visit) {
    var promise = new Promise((resolve) => {
      var payload = visit;
      this.signInReq(resolve, payload);
    });
    return promise;
  }

  signInReq(resolve, payload) {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/signin", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        this.props.fetchVisits(
          moment().subtract(1, "days").format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD")
        );
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("sign In failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
          // console.log(response);
          // console.log('sign In Success')
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  minutesWithLeading(dt) {
    return (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes();
  }

  handleSignOutClickSubmit = () => {
    // write to visit + error handling

    if (
      this.state.signOutPhone === this.state.focusedVisit.patient.cellPhone &&
      !this.sigCanvas2.isEmpty()
    ) {
      this.setState({
        signOutValidated: true,
        signOutDialogue: false,
        signOutSnackbarOpen: true,
      });

      // send request to complete signout
      let payload = {
        _id: this.state.focusedVisit._id,
        signOut: {
          yn: true,
          time: this.state.currentTime,
          signature: this.sigCanvas2.getCanvas().toDataURL("image/png", 1.0),
        },
      };
      this.signOutEvent(payload);

      // not a great way to do this..
    } else {
      // console.log("something went wrong")
      this.setState({
        errorSnackbarOpen: true,
      });
    }
  };

  signOutEvent(visit) {
    var promise = new Promise((resolve) => {
      var payload = visit;
      this.signOutReq(resolve, payload);
    });
    return promise;
  }

  signOutReq(resolve, payload) {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/signout", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        this.props.fetchVisits(
          moment().subtract(1, "days").format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD")
        );

        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("sign out failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
          // console.log(response);
          // console.log('sign out Success')
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  handleSignOutClickClose = () => {
    this.setState({
      signOutDialogue: false,
    });
  };

  handleNewPatientClickClose = () => {
    this.setState({
      newPatientDialogue: false,
    });
  };

  handleNewPatientClickIntake = () => {
    // console.log("route change")
    if (this.state.isReassessment) {
      this.props.history.push(
        "/admin/intake/" + this.state.focusedVisit.patient.id + "/reassessment"
      );
      this.setState({
        focusedVisit: null,
        newPatientDialogue: false,
        reassessment: false,
      });
    } else {
      this.props.history.push("/intake/" + this.state.focusedVisit.patient.id);
      this.setState({
        focusedVisit: null,
        newPatientDialogue: false,
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {console.log("today visits", this.state.todayVisits)}
        {!this.state.visits ? (
          <div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: 500,
                height: 200,
                marginTop: -100,
                marginLeft: -250,
                textAlign: "center",
              }}
            >
              <Typography component="h1" style={{ fontSize: 25 }}>
                Welcome to Appleby Wellness
              </Typography>
              <Typography component="p" style={{ fontWeight: "lighter" }}>
                Loading in your information.
              </Typography>
              <CircularProgress style={{ margin: "3rem" }} />
            </div>
          </div>
        ) : (
          <>
            <ThemeProvider theme={theme}>
              <div style={{ margin: 60, marginTop: 100 }}>
                <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                  WELCOME TO
                </Typography>
                <Typography
                  variant="h3"
                  style={{ textAlign: "left", marginTop: 20 }}
                >
                  The Appleby Wellness Clinic
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: "left", marginTop: 20 }}
                >
                  Please click on your name below to sign in or sign out.
                </Typography>
                <br />
                <hr
                  style={{ opacity: "20%", marginTop: 30, marginBottom: 30 }}
                />
                <br />
                {!this.state.todayVisits.length ? (
                  <Typography variant="subtitle2">
                    There are no visits today. If this is not true, please
                    refresh the page.
                  </Typography>
                ) : (
                  <Grid
                    container
                    justify="left"
                    alignItems="left"
                    spacing={2}
                    style={{ width: "100%" }}
                  >
                    {this.state.todayVisits
                      .sort((a, b) =>
                        a.patient.name.localeCompare(b.patient.name)
                      )
                      .map((visit) => (
                        <Grid
                          key={visit._id}
                          item
                          style={{ textAlign: "center" }}
                        >
                          <Chip
                            avatar={
                              <Avatar
                                style={{
                                  marginRight: 10,
                                  color: "#ffffff",
                                  padding: 2,
                                  backgroundColor: randomColor({
                                    seed: visit.patient.id,
                                    hue: "blue",
                                    luminosity: "bright",
                                  }),
                                }}
                              >
                                {visit.patient.name[0]}
                              </Avatar>
                            }
                            label={
                              <React.Fragment>
                                <div style={{ textAlign: "left" }}>
                                  <Typography variant="body1">
                                    {visit.patient.name}
                                  </Typography>
                                  <Typography variant="caption">
                                    {visit.service[0].type} //&nbsp;
                                    {new Date(visit.date.start).getHours()}:
                                    {this.minutesWithLeading(
                                      new Date(visit.date.start)
                                    )}
                                    &nbsp;-{" "}
                                    {new Date(visit.date.end).getHours()}:
                                    {this.minutesWithLeading(
                                      new Date(visit.date.end)
                                    )}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    style={{ marginTop: -5 }}
                                  >
                                    {/* <br /> */}
                                  </Typography>
                                </div>
                              </React.Fragment>
                            }
                            // label={visit.patient.name}
                            // color="primary"
                            onClick={() => this.handleSignInClick(visit)}
                            variant="outlined"
                            style={{
                              padding: 20,
                              height: 70,
                              width: 260,
                              fontSize: 18,
                              textAlign: "left",
                            }}
                            size="medium"
                          />
                        </Grid>
                      ))}
                  </Grid>
                )}
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: "left", marginTop: 50, opacity: 0.53 }}
                >
                  Don’t see your name? Please ask the front desk for assistance.
                </Typography>
              </div>
              {/* Conditional Content */}
              {this.state.focusedVisit && (
                <>
                  <div>
                    <Dialog
                      open={this.state.signInDialogue}
                      onClose={this.handleSignInClickClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          style={{ color: "black", minWidth: 500 }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ marginTop: 20 }}
                          >
                            WELCOME BACK
                          </Typography>
                          <Typography variant="h4" style={{ marginTop: 20 }}>
                            {this.state.focusedVisit.patient.name}
                          </Typography>
                          <Grid container spacing={3} style={{ marginTop: 20 }}>
                            <Grid item xs={6}>
                              <Typography variant="overline">Date </Typography>
                              <br />
                              <Typography variant="body1">
                                {this.state.focusedVisit.date && (
                                  <>
                                    {String(
                                      months[
                                        new Date(
                                          this.state.focusedVisit.date.start
                                        ).getMonth()
                                      ]
                                    )}{" "}
                                    {String(
                                      new Date(
                                        this.state.focusedVisit.date.start
                                      ).getDate()
                                    )}
                                    ,{" "}
                                    {String(
                                      new Date(
                                        this.state.focusedVisit.date.start
                                      ).getFullYear()
                                    )}
                                  </>
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <InputMask
                                mask="(999) 999-9999"
                                onChange={this.handlesignInPhoneChange}
                                disabled={false}
                                maskChar=" "
                              >
                                {() => (
                                  <TextField
                                    variant={"outlined"}
                                    label="Confirm Phone"
                                    style={{ width: 240 }}
                                  />
                                )}
                              </InputMask>
                              {/* <TextField
                                                ref='email'
                                                label="Confirm Phone"
                                                id="margin-none"
                                                className="email"
                                                onChange={this.handlesignInPhoneChange}
                                                style={{ width: 240 }}
                                                variant={'outlined'}
                                            /> */}
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} style={{ marginTop: 20 }}>
                            <Grid item xs={6}>
                              <Typography variant="overline">
                                Service{" "}
                              </Typography>
                              <br />
                              <Typography variant="body1">
                                {this.state.focusedVisit.service[0].type}
                              </Typography>
                              <br />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="overline">
                                Start Time{" "}
                              </Typography>
                              <br />
                              <Typography variant="body1">
                                {String(this.state.currentTime.getHours())}:
                                {this.state.currentTime.getMinutes() < 10 &&
                                  "0"}
                                {String(this.state.currentTime.getMinutes())}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: -10 }}>
                              <Typography
                                variant="overline"
                                style={{ marginTop: 20 }}
                              >
                                Sign Here{" "}
                              </Typography>
                              <div
                                style={{
                                  borderStyle: "dotted",
                                  borderWidth: 2,
                                  borderColor: "#D3D3D3",
                                  width: 530,
                                  height: 240,
                                  borderRadius: 4,
                                }}
                              >
                                <SignatureCanvas
                                  penColor="black"
                                  ref={(ref) => {
                                    this.sigCanvas = ref;
                                  }}
                                  canvasProps={{
                                    width: 530,
                                    height: 240,
                                    className: "sigCanvas",
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleSignInClickClose}
                          color="primary"
                        >
                          Close
                        </Button>
                        <Button
                          onClick={this.handleSignInClickSubmit}
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={!this.state.signInPhone}
                        >
                          Sign In
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div>
                    <Dialog
                      open={this.state.signOutDialogue}
                      onClose={this.handleSignOutClickClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          style={{ color: "black", minWidth: 500 }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ marginTop: 20 }}
                          >
                            SIGN OUT
                          </Typography>
                          <Typography variant="h4" style={{ marginTop: 20 }}>
                            {this.state.focusedVisit.patient.name}
                          </Typography>
                          <Grid container spacing={3} style={{ marginTop: 20 }}>
                            <Grid item xs={6}>
                              <Typography variant="overline">Date </Typography>
                              <br />
                              <Typography variant="body1">
                                {this.state.focusedVisit.date && (
                                  <>
                                    {String(
                                      months[
                                        new Date(
                                          this.state.focusedVisit.signIn.time
                                        ).getMonth()
                                      ]
                                    )}{" "}
                                    {String(
                                      new Date(
                                        this.state.focusedVisit.signIn.time
                                      ).getDate()
                                    )}
                                    ,{" "}
                                    {String(
                                      new Date(
                                        this.state.focusedVisit.signIn.time
                                      ).getFullYear()
                                    )}
                                  </>
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {/* <TextField
                                                ref='email'
                                                label="Confirm Phone"
                                                id="margin-none"
                                                className="email"
                                                onChange={this.handlesignOutPhoneChange}
                                                style={{ width: 240 }}
                                                variant={'outlined'}
                                            /> */}
                              <InputMask
                                mask="(999) 999-9999"
                                onChange={this.handlesignOutPhoneChange}
                                disabled={false}
                                maskChar=" "
                              >
                                {() => (
                                  <TextField
                                    variant={"outlined"}
                                    label="Confirm Phone"
                                    style={{ width: 240 }}
                                  />
                                )}
                              </InputMask>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} style={{ marginTop: 20 }}>
                            <Grid item xs={6}>
                              <Typography variant="overline">
                                Service{" "}
                              </Typography>
                              <br />
                              <Typography variant="body1">
                                {this.state.focusedVisit.service[0].type}
                              </Typography>
                              {/* <br /><br /> */}
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  <Typography variant="overline">
                                    Start Time{" "}
                                  </Typography>
                                  <br />
                                  <Typography variant="body1">
                                    {this.state.focusedVisit.signIn.time && (
                                      <>
                                        {String(
                                          new Date(
                                            this.state.focusedVisit.signIn.time
                                          ).getHours()
                                        )}
                                        :
                                        {new Date(
                                          this.state.focusedVisit.signIn.time
                                        ).getMinutes() < 10 && "0"}
                                        {String(
                                          new Date(
                                            this.state.focusedVisit.signIn.time
                                          ).getMinutes()
                                        )}
                                      </>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="overline">
                                    End Time{" "}
                                  </Typography>
                                  <br />
                                  <Typography variant="body1">
                                    {String(this.state.currentTime.getHours())}:
                                    {this.state.currentTime.getMinutes() < 10 &&
                                      "0"}
                                    {String(
                                      this.state.currentTime.getMinutes()
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography
                                variant="overline"
                                style={{ marginTop: 20 }}
                              >
                                Sign Here{" "}
                              </Typography>
                              <div
                                style={{
                                  borderStyle: "dotted",
                                  borderWidth: 2,
                                  borderColor: "#D3D3D3",
                                  width: 530,
                                  height: 240,
                                  borderRadius: 4,
                                }}
                              >
                                <SignatureCanvas
                                  penColor="black"
                                  ref={(ref) => {
                                    this.sigCanvas2 = ref;
                                  }}
                                  canvasProps={{
                                    width: 530,
                                    height: 240,
                                    className: "sigCanvas2",
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleSignOutClickClose}
                          color="primary"
                        >
                          Close
                        </Button>
                        <Button
                          onClick={this.handleSignOutClickSubmit}
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={!this.state.signOutPhone}
                        >
                          Sign Out
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div>
                    <Dialog
                      open={this.state.newPatientDialogue}
                      onClose={this.handleNewPatientClickClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          style={{ textAlign: "center", color: "black" }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ marginTop: 20 }}
                          >
                            {this.state.isReassessment
                              ? "WELCOME BACK"
                              : "WELCOME"}
                          </Typography>
                          <Typography variant="h4" style={{ marginTop: 20 }}>
                            {this.state.focusedVisit.patient.name}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ marginTop: 40 }}
                          >
                            {this.state.isReassessment
                              ? "Thank you for visiting Appleby Wellness! Since it has been a while since your last visit, please fill out the following forms again to get started. Please press the button below when you are ready!"
                              : "Thank you for visiting Appleby Wellness! Since this is your first time visiting us, please fill out the following forms to get started. Please press the button below when you are ready!"}
                          </Typography>

                          <Button
                            onClick={this.handleNewPatientClickIntake}
                            variant="contained"
                            color="primary"
                            disableElevation
                            style={{ marginTop: 40 }}
                          >
                            Begin!
                          </Button>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleNewPatientClickClose}
                          color="primary"
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  {/* Snackbars */}
                  <div>
                    <Snackbar
                      open={this.state.signInSnackbarOpen}
                      autoHideDuration={6000}
                      onClose={this.handleSignInSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.handleSignInSnackbarClose}
                        severity="success"
                      >
                        You have signed in successfully!
                      </MuiAlert>
                    </Snackbar>
                  </div>
                  <div>
                    <Snackbar
                      open={this.state.signOutSnackbarOpen}
                      autoHideDuration={6000}
                      onClose={this.handleSignOutSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.handleSignOutSnackbarClose}
                        severity="success"
                      >
                        You have signed out successfully!
                      </MuiAlert>
                    </Snackbar>
                  </div>
                  <div>
                    <Snackbar
                      open={this.state.errorSnackbarOpen}
                      autoHideDuration={6000}
                      onClose={this.handleErrorSnackbarClose}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.handleErrorSnackbarClose}
                        severity="error"
                      >
                        Please check your phone and signature!
                      </MuiAlert>
                    </Snackbar>
                  </div>
                </>
              )}
            </ThemeProvider>
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    visits: state.visits,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
  })
)(withRouter(PatientSignIn));
